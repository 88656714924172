import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Button,
  Input,
  Text,
  VStack,
  HStack,
  useToast,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge
} from '@chakra-ui/react';
import { PhoneIcon, SmallCloseIcon } from '@chakra-ui/icons';
import { db, auth } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

/**
 * פונקציה לעיצוב המספר לפני שליחתו לשרת:
 * אם מתחיל ב-0 => יתווסף +972
 * אם מתחיל ב-972 => נוסיף +
 * אחרת => כבר צריך להיות בתצורה +972
 */
const formatPhoneNumber = (phone) => {
  let cleanPhone = phone.replace(/[^\d+]/g, '');
  if (cleanPhone.startsWith('+972')) {
    return cleanPhone;
  }
  if (cleanPhone.startsWith('972')) {
    return '+' + cleanPhone;
  }
  if (cleanPhone.startsWith('0')) {
    return '+972' + cleanPhone.slice(1);
  }
  return '+972' + cleanPhone;
};

/**
 * פונקציה להצגת המספר (כדי להראות למשתמש 05X)
 */
const displayPhoneNumber = (phone) => {
  if (phone.startsWith('+972')) {
    return '0' + phone.slice(4);
  }
  return phone;
};

const CallSection = ({ selectedContacts, serverUrl }) => {
  const [agentPhone, setAgentPhone] = useState('');
  const [maxAttempts, setMaxAttempts] = useState('3'); // ברירת מחדל 3, נשמר כטקסט
  const [callStatus, setCallStatus] = useState('idle');
  const [callsMade, setCallsMade] = useState({});
  const [isCallActive, setIsCallActive] = useState(false);
  const [contacts, setContacts] = useState([]);
  const toast = useToast();

  useEffect(() => {
    // שליפת רשימת אנשי הקשר מה-Firebase
    const fetchContacts = async () => {
      const user = auth.currentUser;
      if (user) {
        const contactsRef = collection(db, `users/${user.uid}/contacts`);
        const snapshot = await getDocs(contactsRef);
        const contactsList = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data()
        }));
        setContacts(contactsList);
      }
    };
    fetchContacts();
  }, []);

  /**
   * פונקציה להתחלת השיחות
   */
  const startCalls = async () => {
    // מוודאים שנבחר לפחות איש קשר אחד, ושסיפקנו מספר סוכן (agentPhone)
    if (!selectedContacts.length || !agentPhone) {
      toast({
        title: 'שגיאה',
        description: 'אנא מלא את המספר שלך ובחר אנשי קשר',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
      return;
    }

    setCallStatus('מתחיל שיחות');
    setIsCallActive(true);

    try {
      // עבור כל איש קשר שנבחר, מבצעים בקשה לשרת Node.js
      for (const contactId of selectedContacts) {
        const contact = contacts.find((c) => c.id === contactId);
        if (contact) {
          const response = await fetch(`${serverUrl}/api/make_call`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              to_number: formatPhoneNumber(contact.phone),
              agent_number: formatPhoneNumber(agentPhone),
              max_attempts: parseInt(maxAttempts, 10)
            })
          });

          if (response.ok) {
            const data = await response.json();
            // שים לב: השרת מחזיר session_id (ולא call_sid)
            setCallsMade((prev) => ({
              ...prev,
              [contact.id]: {
                number: contact.phone,
                status: 'initiated',
                call_id: data.session_id // כאן משתמשים ב-session_id
              }
            }));
          }
        }
      }

      toast({
        title: 'שיחות החלו',
        description: 'המערכת החלה בביצוע השיחות',
        status: 'success',
        duration: 3000,
        isClosable: true
      });
    } catch (error) {
      console.error('שגיאה בהתחלת השיחות:', error);
      toast({
        title: 'שגיאה',
        description: 'אירעה שגיאה בהתחלת השיחות',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
      setIsCallActive(false);
      setCallStatus('שגיאה');
    }
  };

  /**
   * פונקציה לבדיקת סטטוס השיחות באופן מחזורי
   */
  const checkCallStatus = useCallback(async () => {
    // עבור כל שיחה שקיימת ב-callsMade, בדוק את הסטטוס בשרת
    const statusChecks = Object.entries(callsMade).map(async ([contactId, callInfo]) => {
      try {
        if (!callInfo.call_id) return null; // אין Session ID, דלג
        // הנתיב הנכון: /api/call_session_status/:sessionId
        const response = await fetch(`${serverUrl}/api/call_session_status/${callInfo.call_id}`);
        if (response.ok) {
          const data = await response.json();
          // במידה והשרת מחזיר איזשהו שדה לתיאור הסטטוס, אפשר לטפל פה
          // לעת עתה נסתפק בשדה 'current_attempt' או 'active_call_sid' וכו'.
          // אין שדה 'status' ישיר, אז נקבע ידנית ערך כלשהו
          // אפשר להרחיב לפי הצורך.

          // לדוגמה, אם current_attempt > 0 => נעשה 'in-progress' וכו'.
          // או שאפשר להשאיר את זה בסיסי:
          return { contactId, status: 'in-progress' };
        }
      } catch (error) {
        console.error(`שגיאה בבדיקת סטטוס לשיחה ${callInfo.call_id}:`, error);
      }
      return null;
    });

    const results = await Promise.all(statusChecks);
    const updatedCalls = { ...callsMade };

    results.forEach((result) => {
      if (result && result.contactId) {
        updatedCalls[result.contactId] = {
          ...updatedCalls[result.contactId],
          status: result.status
        };
      }
    });

    setCallsMade(updatedCalls);
  }, [callsMade, serverUrl]);

  useEffect(() => {
    let interval;
    if (isCallActive) {
      // כל 3 שניות בודקים סטטוס
      interval = setInterval(checkCallStatus, 3000);
    }
    return () => clearInterval(interval);
  }, [isCallActive, checkCallStatus]);

  /**
   * פונקציה להפסקת השיחות
   */
  const stopCalls = async () => {
    try {
      // שולח בקשות end_call לכל מה שיש לנו ב-callsMade
      const stopPromises = Object.values(callsMade).map(async (callInfo) => {
        if (callInfo.call_id) {
          return fetch(`${serverUrl}/api/end_call/${callInfo.call_id}`, {
            method: 'POST'
          });
        }
      });

      await Promise.all(stopPromises);

      setIsCallActive(false);
      setCallStatus('הופסק');
      toast({
        title: 'השיחות הופסקו',
        description: 'כל השיחות הפעילות הופסקו',
        status: 'info',
        duration: 3000,
        isClosable: true
      });
    } catch (error) {
      console.error('שגיאה בהפסקת השיחות:', error);
      toast({
        title: 'שגיאה',
        description: 'נכשל בהפסקת השיחות',
        status: 'error',
        duration: 3000,
        isClosable: true
      });
    }
  };

  /**
   * בחירת צבע Badge לפי הסטטוס
   */
  const getStatusBadge = (status) => {
    switch (status) {
      case 'initiated':
        return <Badge colorScheme="blue">מתחיל</Badge>;
      case 'in-progress':
        return <Badge colorScheme="green">פעיל</Badge>;
      case 'completed':
        return <Badge colorScheme="gray">הסתיים</Badge>;
      case 'failed':
        return <Badge colorScheme="red">נכשל</Badge>;
      case 'connected':
        return <Badge colorScheme="purple">מחובר</Badge>;
      case 'timeout':
        return <Badge colorScheme="yellow">לא ענה</Badge>;
      default:
        return <Badge colorScheme="yellow">לא ידוע</Badge>;
    }
  };

  /**
   * ממשק הצגת השיחות
   */
  return (
    <Box mt={4}>
      <VStack spacing={4} align="stretch">
        <Input
          placeholder="המספר שלך (לחיבור השיחות)"
          value={agentPhone}
          onChange={(e) => setAgentPhone(e.target.value)}
        />
        <Input
          placeholder="מספר ניסיונות מקסימלי"
          value={maxAttempts}
          onChange={(e) => setMaxAttempts(e.target.value)}
          type="number"
        />
        <HStack>
          <Button
            leftIcon={<PhoneIcon />}
            colorScheme="teal"
            onClick={startCalls}
            isDisabled={isCallActive}
          >
            התחל שיחות
          </Button>
          <Button
            leftIcon={<SmallCloseIcon />}
            colorScheme="red"
            onClick={stopCalls}
            isDisabled={!isCallActive}
          >
            הפסק שיחות
          </Button>
        </HStack>

        <Text fontWeight="bold">סטטוס: {callStatus}</Text>

        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>שם</Th>
              <Th>מספר טלפון</Th>
              <Th>סטטוס</Th>
            </Tr>
          </Thead>
          <Tbody>
            {Object.entries(callsMade).map(([contactId, callInfo]) => {
              const contact = contacts.find((c) => c.id === contactId);
              if (!contact) return null;

              return (
                <Tr key={contactId}>
                  <Td>{contact.name}</Td>
                  <Td>{displayPhoneNumber(contact.phone)}</Td>
                  <Td>{getStatusBadge(callInfo.status)}</Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </VStack>
    </Box>
  );
};

export default CallSection;
